import { Options } from "vue-class-component";
import BaseTableInterface from "@/mixins/BaseTableInterface";
import BaseTableMixins from "@/mixins/BaseTable";
import SelectLevel from "@/modules/select-level/select-level.vue";
import SelectDept from "@/modules/select-dept/select-dept.vue";
import SelectRole from "@/modules/select-role/select-role.vue";
import UserAffiliateTableItem from "@/entity/system/UserAffiliateTableItem";
import RoleTableItem from "@/entity/system/master/RoleTableItem";
import API from "@/api/system/user/affiliate";
import OrgAPI from "@/api/system/org";
import DeptAPI from "@/api/system/master/dept";
import PostAPI from "@/api/system/master/post";

//用户角色数据列表
export class EditRoleList {
    roleList: Array<RoleTableItem>;
    roleSelected: any;
}

@Options({
    name: "app-system-user-affiliate",
    components: {
        "app-select-level": SelectLevel,
        "app-select-dept": SelectDept,
        "app-select-role": SelectRole,
    },
})
export default class Affiliate extends BaseTableMixins<UserAffiliateTableItem> implements Partial<BaseTableInterface<UserAffiliateTableItem>> {
    public created(): void {
        this.setInterface(this);
        this.tableRequestItem.lockItem = this.getLockItem();
        this.editTableItem = new UserAffiliateTableItem();
        this.editModalItem = new UserAffiliateTableItem();
    }

    //锁定列数据实体
    public getLockItem(): any {
        return {
            orgCode: {
                type: "router",
                name: "u.org_code",
                title: "所属组织",
                parent: this.authUser.orgCode,
                value: null,
                openFn: this.orgCodeLevelModalOpen,
            },
            deptId: {
                type: "router",
                name: "u.dept_id",
                title: "所属部门",
                parent: "O" + this.authUser.orgCode + "D-1",
                value: null,
                openFn: this.deptIdLevelModalOpen,
            },
            postId: {
                type: "eq",
                name: "u.post_id",
                title: "所在岗位",
                parent: null,
                value: null,
                openFn: this.postIdLevelModalOpen,
            },
        };
    }

    public activated(): void {
        this.getList(1);
    }

    //OrgAPI
    get getOrgApi(): any {
        return OrgAPI;
    }

    //DeptAPI
    get getDeptApi(): any {
        return DeptAPI;
    }

    //PostAPI
    get getPostApi(): any {
        return PostAPI;
    }

    //后端API
    public getApi(): any {
        return API;
    }

    // //列表回调
    // public listCallback(items: Array<UserAffiliateTableItem>): void {
    //     return;
    // }

    //添加弹窗配置
    public addModalSetting(): void {
        this.editTableItem = new UserAffiliateTableItem();
        this.editModalItem = new UserAffiliateTableItem();
        this.editModalItem.userStatus = 1;
        this.editModalTitle = "添加用户";
        this.editModalSubmitButtonName = "添加";
    }
    //添加提交检测
    public addSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.userCode)) return "用户工号不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.userName)) return "用户名称不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.userStatus)) return "用户状态不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.realName)) return "真实姓名不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.orgCode)) return "所属组织不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.deptId)) return "所属部门不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.postId)) return "所在岗位不能为空！";
        return null;
    }
    // //添加回调
    // public addModalCallback(res: any): void {
    //     return;
    // }

    //修改弹窗配置
    public modModalSetting(): void {
        this.editModalTitle = "修改用户 (" + this.editModalItem.userName + ")";
        this.editModalSubmitButtonName = "修改";
    }
    //修改提交检测
    public modSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.userCode)) return "用户工号不能为空！";
        return this.addSubmitChecking();
    }
    // //修改回调
    // public modModalCallback(res: any): void {
    //     return;
    // }

    //删除提示框配置
    public delAlertConfig(): any {
        return {
            type: "okAndNo",
            title: "删除用户",
            message: "用户名称: " + this.editModalItem.realName + "(" + this.editModalItem.userName + ")<br>请确认删除？",
        };
    }
    // //删除提示框配置
    // public delAlertSetting(): void {
    //     return;
    // }
    //删除提示框提交检测
    public delSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.userCode)) return "用户工号不能为空！";
        return null;
    }
    // //删除回调
    // public delAlertCallback(res: any): void {
    //     return;
    // }

    //状态提示框配置
    public statusAlertConfig(): any {
        return {
            title: "用户状态",
            message: "用户名称: " + this.editModalItem.realName + "(" + this.editModalItem.userName + ")<br>请确认修改状态？",
            submitButtonName: this.editModalItem.userStatus === 1 ? "启用" : "禁用",
        };
    }
    //状态切换请求配置
    public toggleStatusSetting(): void {
        this.editModalItem.userStatus = this.editModalItem.userStatus === 1 ? 2 : 1;
    }
    //状态切换提交检测
    public toggleStatusSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.userCode)) return "用户工号不能为空！";
        return null;
    }
    // //状态切换回调
    // public toggleStatusCallback(): void {
    //     return;
    // }

    // 配置用户角色
    protected editRoleBaseTitle = "配置用户角色";
    private editRoleTitle: string = this.editRoleBaseTitle;
    private editRoleList: EditRoleList = new EditRoleList();
    public roleSelectOpen(item: UserAffiliateTableItem) {
        if (item.orgCode < 1) {
            this.toast.error("请先配置所属组织！");
            return Promise.reject(false);
        }
        this.editModalItem = item;
        const editRoleSelected: any = {};
        const roleIdList = this.$tools.isEmpty(item.roleIdList) ? [] : item.roleIdList.split(",");
        for (let i = 0; i < roleIdList.length; i++) {
            editRoleSelected[roleIdList[i]] = true;
        }
        this.editRoleList.roleList = [];
        this.editRoleList.roleSelected = [];
        this.isLoading = true;
        API.roleSelect(item)
            .then((res: any) => {
                this.editRoleTitle = this.editRoleBaseTitle + ": " + item.userName;
                this.editRoleList.roleList = res.data.records;
                this.editRoleList.roleSelected = editRoleSelected;
                //console.log(this.editSortList);
                (this.$refs.roleSelectModal as any).openModal();
            })
            .finally(() => {
                this.isLoading = false;
            });
    }
    // 保存用户角色
    public async roleSelectSubmit(): Promise<boolean> {
        const roleSelected = Object.assign({}, this.editRoleList.roleSelected);
        //console.log(roleSelected);
        const query: any = {};
        for (let i = 0; i < this.editRoleList.roleList.length; i++) {
            const roleItem: RoleTableItem = this.editRoleList.roleList[i];
            if (roleItem.roleId && roleSelected[roleItem.roleId]) query[roleItem.roleId] = roleSelected[roleItem.roleId];
        }
        if (this.$tools.isEmpty(query)) {
            this.toast.error("请选择用户角色！");
            return Promise.reject(false);
        }
        await API.roleSelectSave({
            userCode: this.editModalItem.userCode,
            roleSelected: query,
        }).then((res: any) => {
            this.toast.success(res.msg);
            this.getList(0);
        });
        return Promise.resolve(true);
    }

    //orgCode搜索字段
    private orgCodeLevelSearchField: any = [
        { field: "org_code", name: "代码" },
        { field: "org_name", name: "名称" },
        { field: "org_remark", name: "说明" },
    ];
    //orgCode层级选择器打开
    public orgCodeLevelModalOpen() {
        (this.$refs.orgCodeLevel as any).openModal();
    }
    //orgCode层级选择器提交
    public async orgCodeLevelFunction(item: any): Promise<void> {
        const curItem = this.$tools.isEmpty(item.value) ? this.getLockItem().orgCode : item;
        this.tableRequestItem.lockItem.orgCode.value = curItem.value;
        this.tableRequestItem.lockItem.orgCode.title = curItem.title;
        this.tableRequestItem.lockItem.orgCode.parent = curItem.parent;
        await this.getList(1);
        return Promise.resolve();
    }

    //deptId搜索字段
    private deptIdLevelSearchField: any = [
        { field: "dept_name", name: "名称" },
        { field: "dept_remark", name: "说明" },
    ];
    //deptId层级选择器打开
    public deptIdLevelModalOpen() {
        (this.$refs.deptIdLevel as any).openModal();
    }
    //deptId层级选择器提交
    public async deptIdLevelFunction(item: any): Promise<void> {
        const curItem = this.$tools.isEmpty(item.value) ? this.getLockItem().deptId : item;
        this.tableRequestItem.lockItem.deptId.value = curItem.value;
        this.tableRequestItem.lockItem.deptId.title = curItem.title;
        this.tableRequestItem.lockItem.deptId.parent = curItem.parent;
        await this.getList(1);
        return Promise.resolve();
    }

    //postId搜索字段
    private postIdLevelSearchField: any = [
        { field: "post_name", name: "名称" },
        { field: "post_remark", name: "说明" },
    ];
    //postId层级选择器打开
    public postIdLevelModalOpen() {
        (this.$refs.postIdLevel as any).openModal();
    }
    //postId层级选择器提交
    public async postIdLevelFunction(item: any): Promise<void> {
        const curItem = this.$tools.isEmpty(item.value) ? this.getLockItem().postId : item;
        this.tableRequestItem.lockItem.postId.value = curItem.value;
        this.tableRequestItem.lockItem.postId.title = curItem.title;
        this.tableRequestItem.lockItem.postId.parent = curItem.parent;
        await this.getList(1);
        return Promise.resolve();
    }

    get orgCodeSelectApi() {
        return {
            api: (query: any) => OrgAPI.idSelect(query),
            parent: "org_parent_code",
            status: "org_status",
            name: "org_name",
            id: "org_code",
            extend: "false",
            cid: this.editModalItem.orgCode,
            affiliate: "true",
        };
    }

    get deptIdSelectApi() {
        return {
            api: (query: any) => DeptAPI.idSelect(query),
            parent: "dept_parent_id",
            status: "dept_status",
            name: "dept_name",
            id: "dept_id",
            cid: this.editModalItem.deptId,
            affiliate: "true",
        };
    }

    get postIdSelectApi() {
        return {
            api: (query: any) => PostAPI.idSelect(query),
            status: "post_status",
            name: "post_name",
            id: "post_id",
            cid: this.editModalItem.postId,
        };
    }
}
